/* =============================================================================
   Primary Styles
   Author: rsacek
   ========================================================================== */





/*
  Imports
  Modify Bootstrap defaults...

  Note: This is duplicated below but we have mixed scss variables and css variables so its probably ok but could be cleaned up farther. Maybe Scss vars set our css vars?
*/
$enable-validation-icons: false;
$input-placeholder-color: #ca9a8e;
$modal-backdrop-bg: rgba(250, 246, 245, 1.0);



@import "~bootstrap/scss/bootstrap";
//@import "fonts/FontAwesome/solid";



/*

** z-index usage **

1     Key Cards Backdrop over card
2     Key Cards Overlay image.

1     SpeakerProfileModal - close button (top/right)

499 Fixed Right Floating button


500  CategoryFilterSelection - Full screen mask - NEEDS TO BE HIGH (1000)
501  CategoryFilterSelection - Menu, sits above fullscreen mask.   (1001)

500  FilterSelectionGeneric - Full screen mask - NEEDS TO BE HIGH  (1000)
501  FilterSelectionGeneric - Menu, sits above fullscreen mask.    (1001)


1040  Backdrop     - Used by SubmitOverlay
1040  Large Spinner


600  ISI Product floating bottom fixed popup
601  Cookie Consent - Footer

602 Return To Top floating button


500    Header - fullNavBarMask      - Full Nav Mask
501    Header - dropdown            - Full Nav Top Menu Items (In top nav bar)
502    Header - fullNavbar.dropdown - Full Nav Opened Menu with items.
502    Header - dropdownContent     - Item in drop down.

500    Header - miniMenuMask        - Mask
501    Header - miniMenuSection     - Mini menu opened

// OLD 99    Header - fullNavBarMask   1500      - 500 - Full Nav Mask
// OLD 100   Header - dropdown         1510      - 501 - Full Nav Top Menu Items
// OLD 200   Header - fullNavbar.dropdown - 1520 - 502 - Full Nav Opened Menu with items.
//OLD 1     Header - dropdownContent  1520      - 502

// OLD 99    Header - miniMenuMask     500  - Mask
// OLD 1100  Header << miniMenuSection 501  - Mini menu opened


1     WistiaManager - ???
9000  ServiceWorkerWrapper - NOT USED


$zindex-dropdown:          1000 !default;
$zindex-sticky:            1020 !default;
$zindex-fixed:             1030 !default;
$zindex-modal-backdrop:    1040 !default;
$zindex-modal:             1050 !default;
$zindex-popover:           1060 !default;
$zindex-tooltip:           1070 !default;

For CustomAlert use z-index starting at 1051+

 */






/* =============================================================================
   Fonts
   ========================================================================== */


@font-face {
  font-family: 'BeatriceLight';
  /*src: url('Beatrice-Light.eot');*/
  src:
          url('fonts/Beatrice_Light/Beatrice-Light.eot?#iefix') format('embedded-opentype'),
          url('fonts/Beatrice_Light/Beatrice-Light.woff2') format('woff2'),
          url('fonts/Beatrice_Light/Beatrice-Light.woff') format('woff'),
          url('fonts/Beatrice_Light/Beatrice-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-stretch:normal;
  font-display: block;
}

@font-face {
  font-family: 'BeatriceRegular';
  /*src: url('Beatrice-Light.eot');*/
  src:
          url('fonts/Beatrice_Regular/Beatrice-Regular.eot?#iefix') format('embedded-opentype'),
          url('fonts/Beatrice_Regular/Beatrice-Regular.woff2') format('woff2'),
          url('fonts/Beatrice_Regular/Beatrice-Regular.woff') format('woff'),
          url('fonts/Beatrice_Regular/Beatrice-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-stretch:normal;
  font-display: block;
}

//todo replace font when i know that it has been replaced everywhere

/*
 * ASE 2.0 Font Graphik
 */

@font-face {
  font-family: 'Graphik';
  src: url('fonts/Graphik/Graphik-Regular-Web.woff2') format('woff2'),
  url('fonts/Graphik/Graphik-Regular-Web.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('fonts/Graphik/Graphik-RegularItalic-Web.woff2') format('woff2'),
  url('fonts/Graphik/Graphik-RegularItalic-Web.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('fonts/Graphik/Graphik-Medium-Web.woff2') format('woff2'),
  url('fonts/Graphik/Graphik-Medium-Web.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

// missing woff2
@font-face {
  font-family: 'Graphik';
  src: /*url('fonts/Graphik/Graphik-MediumItalic-Web.woff2') format('woff2'),*/
  url('fonts/Graphik/Graphik-MediumItalic-Web.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}





/* =============================================================================
   Variables

   ========================================================================== */
:root {

  /*
   **  v2 Colors - Ok so we had a system and these color don't quite fit. soo my thoughts I will define them all hear and then use them in our system to give them context when I can.
   ** Note: The top definition really isn't suppose to be used directly Unless its really a one off with no global context.
   */

  --black: #000;
  --white: #fff;

  --warm-linen: #fcf4e8;
  --dusty-rose: #c08676;
  --dusty-rose-20: #f7eeea;
  --dusty-rose-40: #efded5;
  --dusty-rose-60: #e7cdc0;
  --dusty-rose-80: #dfbdab;
  --neutral-grey-20: #fbfaf8;
  --neutral-grey-40: #f7f5f1;
  --neutral-grey-60: #f2f0ec;
  --neutral-grey-80: #e9e5de;

  //Colors
  --default-text: var(--black); //was #212529; // color provided by bootstrap but will change if used in a dark theme so overriding here
  --primary-background:var(--warm-linen);
  --primary: var(--black); //old#071d49;
  --secondary:var(--dusty-rose);
  --secondary-light:var(--dusty-rose-80);

  --high-contrast:#000; // Todo text that uses this should probably be default-text

  // todo these are old // these are in this format to support fading / animation
  --light-gray:rgba(250, 246, 245, 1.0); // todo these are old // #faf6f5; //not sure what to call this one. Used for hover highlights and background adding it to things to be left alone for now
  --accent-background:rgba(242,217,208, 1.0); // #f2d9d0; //todo I don't know if this exists anymore there is however some white bg

  --secondary-dark:#492728; //todo nothing matches this find out where this is used
  --accent:#ff9c7e; // todo I don't know if we have a accent color in this palette

  // this really should be black but it works for our site. if on a dark background it will look like a glow
  // todo based on the new design these should probably go away
  --shadow-base: 73, 39, 40;
  --shadow-alpha: 0.52;
  --shadow-alpha-light: 0.18;

  --box-shadow-dark: 1px 3px 13px 0 rgba(var(--shadow-base), var(--shadow-alpha));
  --box-shadow-light: 1px 3px 13px 0 rgba(var(--shadow-base), var(--shadow-alpha-light));

  --isi-cookie-inset-box-shadow: inset 7px 10px 12px #f0f0f0;

  --error: #CC0000;
}


/* =============================================================================
   Context Colors Classes
   ========================================================================== */

/* =============================================================================
   Text Colors
   ========================================================================== */

//This color is so subtly different from the main color that im not sure its needed (used in the footer and media Card)
.black {
  color: var(--high-contrast);
}

.link-text{
  color: var(--high-contrast);
  text-decoration: underline;
}

.link-styled-button {
  color: var(--high-contrast);
  text-decoration: underline;
  border: none;
  text-align: left;
  cursor: pointer;
  background-color: transparent;
  line-height: 1.4;
}

.secondary-text {
  //color: var(--secondary);
}

.secondary-light {
  color: var(--dusty-rose-40);
}

.secondary-dark-text {
  color: var(--secondary-dark);
}

.error-color { //I think Error is ok
  color: var(--error);
}

/* =============================================================================
   Background / Divider Colors
   ========================================================================== */

.light-gray-bg { //Used lots of places
  //background-color: var(--light-gray) !important;

  //background-color: var(--primary-background) !important;
}

.divider-default-bg {
  background-color: var(--high-contrast);
}

.divider-accent-bg {
  background-color: var(--accent-background) !important;
}

/* =============================================================================
   Global
   ========================================================================== */


html {
  height: 100%;
}

*:focus, textarea:focus, input:focus, button:focus {
  outline: 0 !important;
  outline: none !important;
  box-shadow: none !important;
}

.not-selectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  color:var(--default-text);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Default Font
  font-family: neue-haas-unica, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;

  //margin-top: 76px;
  //padding-top: 76px;
  // margin-top: calc( env(safe-area-inset-top, 0px) + 76px); // RJS fullscreen fix - ipad does not adjust.

  //margin-bottom: 96px;
  //padding-bottom: 57px;

  background-color: var(--primary-background);

  &.no-overflow {
    overflow-x: hidden;
  }
}




/* =============================================================================
   Typography
   ========================================================================== */

.header-base {
  font-family: Graphik, BeatriceLight, Helvetica, sans-serif;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

h1, .h1 {
  @extend .header-base;
  font-size: 48px; // was 45px
  line-height: 1.3; //was 1.18;
  font-weight: 500;
}

h2, .h2 {
  @extend .header-base;
  font-size: 32px; // was 32px
  line-height: 1.3;
  font-weight: 500;
}

h3, .h3 {
  @extend .header-base;
  font-size: 28px;
  line-height: 1.2;
  font-weight: 500;
}

// todo this is not listed in design
h4, .h4 {
  @extend .header-base;
  font-size: 22px;
  line-height: 1.36;
}

.para-base-type-1 {
  font-family: neue-haas-unica, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.p-large {
  @extend .para-base-type-1;
  font-size: 18px;
  line-height: 22px;
}

.p-regular {
  @extend .para-base-type-1;
  font-size: 16px;
  line-height: 22px;
}

.p-small {
  @extend .para-base-type-1;
  font-size: 13px;
  line-height: 16px;
}

// todo this is not listed in design
.p-extra-small {
  @extend .para-base-type-1;
  font-size: 14px;
  line-height: 1.43;
}

// todo this is not listed in design
// only used in Territory metric report but seems like it could have been used else where
.p-micro-light {
  @extend .para-base-type-1;
  font-size: 10px;
  font-weight: 300;
  line-height: 1.43;
}

.para-base-type-2 {
  font-family: Graphik, BeatriceLight, Helvetica, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.testimonials-text {
  @extend .para-base-type-2;
  font-size: 22px;
  font-weight: 300;
  line-height: 1.36;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.bold-underline {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

.bold-underline:hover {
  text-decoration-thickness: 2px;
}

em {
  font-weight: 500;
  font-style: normal;
}

strong {
  font-weight: 600;
}

.bold {
  font-weight: 500 !important;
}

.bolder {
  font-weight: 700 !important;
}

.light {
  font-weight: 300 !important;
}

//p {
//
//  &.lg {
//  }
//
//  &.md {
//  }
//
//	&.sm {
//	}
//}
//
a {

  //color: var(--secondary);
  color: var(--primary);

  &:hover {
    //color: var(--secondary);
    color: var(--primary);
  }
}


.ul-custom {
  margin: 0 0 15px 0;
  padding: 0;
  list-style: none;
  list-style-type: none;
}


textarea {
  padding:10px 15PX;
}


.label-5 {
  font-weight: 500
}

/* =============================================================================
   Buttons
   ========================================================================== */


.btn {

  border: none;
  // min-height: 44px;

  font-family: Graphik, sans-serif;
  //font-size: 18px;
  //font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  //line-height: 1.44;
  letter-spacing: normal;
  border-radius: 8px;

  color: var(--primary);

  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;


  &.size_250_50 {
    width:250px;
    height:50px;
  }

  &.min_width_200 {
    min-width:200px;
  }

  &.min_width_150 {
    min-width:150px;
  }

  &.btn-primary{
    min-height: 40px;
    min-width: 103px;
    padding-left: 18px;
    padding-right: 18px;

    border: solid 2px var(--primary);
    background-color: transparent;
    text-align: left;

    &:hover,
    &:focus
    {
      //background-color: white !important;
      border-color: var(--primary) !important;
      color: var(--primary) !important;
    }

    &.disabled,
    &:disabled {
      color: var(--primary) !important;
      opacity:.4;
    }

    &:active,
    &.active
    {
      background-color: transparent !important;
      //background-color: var(--light-gray) !important;
      border-color: var(--primary) !important;
      color: var(--primary) !important;
    }

  }

// todo right now this seems to function as a button with no arrow but that arrow doesn't seem to so up in the new designs
  &.btn-secondary {
    //border: 1px solid var(--secondary-dark);
    //background-color: var(--white) !important;
    //color: var(--secondary-dark);
  }


  &.btn-secondary-light {
    border: 1px solid var(--secondary-light);
    background-color: var(--secondary-light);
    color: var(--white);
  }

  &.btn-dark-brown {
    background-color: var(--secondary-dark);
    color: var(--white);
  }

  &.btn-secondary-filled {
    background-color: var(--secondary);
    color: var(--white);
  }


  //&:hover, &:focus, &:active, &.active {
  //  background-color: var(--white);
  //}
}


.clickable {
  &:hover {
    cursor: pointer;
  }
}


.medium_width_view {
  max-width:732px;
}


/* =============================================================================
   iOS Fixes
   ========================================================================== */

/*
  Padding to fix iOS and Safari issues with content on bottom being cut off.
  This is not a Notch Issue.
  On iOS, when the bottom control bar is displayed (on iphones) the bar covers up the content.
  This is a known issue with Apple.
  iPads also have a similiar issue when calculating height of the content area.
 */
.ios-padding-fix,
.safari-padding-fix {
  padding-bottom: 80px;
  // background-color: red;
}




/* =============================================================================
   Icons
   ========================================================================== */
//i.fas, i.far {
//
//
//  &.sm {
//    color: var(--light-color-text) !important;
//    font-size: 1.4em;
//  }
//
//  &.lg {
//    font-size: 1.6em;
//  }
//}






/* =============================================================================
   Forms
   ========================================================================== */
//input[type="search"]::-webkit-search-decoration,
//input[type="search"]::-webkit-search-cancel-button,
//input[type="search"]::-webkit-search-results-button,
//input[type="search"]::-webkit-search-results-decoration { display: none; }
//input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
//input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }
//
//
//.form-control, .custom-select {
//
//	&:focus {
//	}
//}
//
//.custom-control-input:focus ~ .custom-control-label::before {
//	box-shadow: none !important;
//}

.custom-input-group-text {
  background-color: var(--white);
  border-color:black var(--high-contrast);

  &:hover {
    cursor: pointer;
  }
}

.form-group .custom-select, .custom-control .custom-select {
  //background: #fff url(../images/ico-dropdown.png) no-repeat right 0.75rem center;
  cursor: pointer;
  //font-size: 1.4em;
  //line-height: 1.5em;
}


.form-group label, .custom-form-lable-1 {

  margin-bottom: 0;

  font-size: 18px;
  font-weight: 500;
}


.form-group .form-text-small {
  font-style: normal;
  font-weight: normal;
  color:var(--high-contrast);
}


.form-group .form-control, .custom-control .form-control, .form-group .custom-select, .custom-control .custom-select {

  height: 50px;

  border: 1px solid var(--high-contrast);

  font-size: 18px;
  font-weight: normal;

  color: var(--high-contrast);
}


.form-group .invalid-feedback {

  margin-bottom: 0;

  font-size: 14px;
}



.form-group small, .form-group .small {

  margin-bottom: 0;

  font-size: 14px;
  font-weight: 500;
  font-style:italic;
}


//
// Form field validation color overrides.
//
.form-control.is-valid, .was-validated .form-control:valid {
  border-color: var(--high-contrast);
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: var(--high-contrast);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--high-contrast);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: var(--high-contrast) !important;
}





//
// Make the Checkbox bigger and change its color.
//

input[type="checkbox"] {
  width: 30px;
  height: 30px;
}
input[type="radio"] {
  width: 30px;
  height: 30px;
}


.custom-checkbox .custom-control-label{

  padding-top: 2px;
  line-height: 22px;
  font-size: 18px;
}

.custom-control-label:before{

  margin-left: 5px;

  border-color: var(--secondary);

  width: 20px;
  height: 20px;
}


.custom-checkbox .custom-control-input:checked~.custom-control-label::before{

  //background-color:var(--white);
  background-color:var(--secondary);
  border-color: var(--secondary);
}


.custom-checkbox .custom-control-input:checked~.custom-control-label::after{
  border-color: var(--secondary);
  width: 30px;
  height: 30px;

  margin-top: -5px;

  background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='white' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:focus~.custom-control-label::before{
  border-color: var(--secondary);
  //box-shadow: 0 0 0 0 #000, 0 0 0 0.2rem rgba(100, 0, 0, 0.25);
  box-shadow: none;
}





//
// Make the Radio bigger and change its color.
//
.custom-radio .custom-control-input:checked~.custom-control-label::before{

  background-color:var(--white);
  border-color: var(--secondary);
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color:var(--white);
  border-color: var(--secondary);
}

.custom-radio .custom-control-input:checked~.custom-control-label::after{
  border-color: var(--secondary);
  width: 35px;
  height: 35px;

  margin-top: -7px;
  margin-left:-3px;

  background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='sienna' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  //background-image:url(../images/icons/ico-plus.svg);
  background-repeat: no-repeat;
  background-size: 15px 15px;
}

.custom-radio .custom-control-input:focus~.custom-control-label::before{
  border-color: var(--secondary);
  box-shadow: 0 0 0 0 #000, 0 0 0 0.2rem rgba(100, 0, 0, 0.25);
}


.form-error-message {
  font-size: 18px;
  color: var(--error);
}

.form-field-error-message {
  font-size: 16px;
  color: var(--error);
}


.form-field-custom-error-message-sm {
  font-size: 14px;
  color: var(--error);
}


.custom-textarea-1 {
  height:150px;
  max-width:730px;
}

//todo maybe should not global maybe should not be raiting specific?
.rating-textarea {
  height:125px;
  resize: none;
  border:none;
  border-radius: 5px;
  padding: 16px 20px;
}


/* =============================================================================
   Tables
   ========================================================================== */
//table {
//  &.table {
//
//  }
//
//  &.table-striped {
//  }
//}



/* =============================================================================
   Report Layouts
   ========================================================================== */

.report-type-1-layout {

  table {
    &.report-table-type-1 {
      margin: 0 auto;
      font-size: 12px !important;

      th {
        height: 100%;
        // min-width: 120px;
        padding: 10px 10px;
        text-align: center;
        font-weight: 500;
        color: var(--secondary);
      }

      .report-table-header-row {
        border-top: 2px solid var(--accent-background);
        border-bottom: 2px solid var(--accent-background);
      }

      tbody tr {
        border-bottom: 1px solid var(--accent-background);
      }

      td {
        padding: 20px 10px;
        text-align: center;
      }

      .accent-text-1 {
        color: var(--secondary-dark);
      }
    }
  }


  .report-result-section {

    overflow-x: scroll;
    overflow-y: hidden;
  }

  .download-report-button {
    height:45px;
    padding-bottom:1px;
  }
}


.img-shadow {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}


.fixed-width-30 {
  width:30px;
  min-width:30px;
  max-width:30px;
}

.fixed-width-50 {
  width:50px;
  min-width:50px;
  max-width:50px;
}

.fixed-width-75 {
  width:75px;
  min-width:75px;
  max-width:75px;
}

.fixed-width-100 {
  width:100px;
  min-width:100px;
  max-width:100px;
}

.fixed-width-120 {
  width:120px;
  min-width:120px;
  max-width:120px;
}

.fixed-width-130 {
  width:130px;
  min-width:130px;
  max-width:130px;
}

.fixed-width-150 {
  width:150px;
  min-width:150px;
  max-width:150px;
}

.fixed-width-175 {
  width:175px;
  min-width:175px;
  max-width:175px;
}

.fixed-width-200 {
  width:200px;
  min-width:200px;
  max-width:200px;
}

.fixed-width-225 {
  width:225px;
  min-width:225px;
  max-width:225px;
}

.fixed-width-250 {
  width:250px;
  min-width:250px;
  max-width:250px;
}

.fixed-width-300 {
  width:300px;
  min-width:300px;
  max-width:300px;
}

.fixed-width-350 {
  width:350px;
  min-width:350px;
  max-width:350px;
}

/* =============================================================================
   Hero Banner
   ========================================================================== */

.hero-section {
  width: 100%;

  background-image: linear-gradient(to right, var(--neutral-grey-80), var(--neutral-grey-20) 50%);

  overflow: hidden;
  border-radius: 8px;
}

.hero-section-text-content {
  padding: 28px;
}

.hero-section-img {
  //height: 100%;
  height: 275px;
  object-fit:cover;
}

@media (max-width: 990px) {

  .hero-section-img {
    width: 100%;
    height:auto;
  }

}


/* =============================================================================
   Carousel
   ========================================================================== */

.carousel-indicators {
  background-color: white;
  bottom:-45px;


  li {
    height:15px;
    width:15px;
    background: var(--secondary-light);
    border-radius: 15px;

    border-top:none;
    border-bottom:none;
  }
}



@media only screen and (min-width: 1199px) {
  .custom-carousel {
    height: 530px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1198px) {
 .custom-carousel {
   height: 570px;
 }
}


@media only screen and (min-width: 768px) and (max-width: 999px) {
 .custom-carousel {
   height: 750px;
 }
}

// BAD
//@media only screen and (min-width: 650px) and (max-width: 767px) {
//  .custom-carousel {
//    height: 1200px;
//  }
//}



/* =============================================================================
   Modal
   ========================================================================== */


.modal-content {
  border-radius: 0;
  box-shadow: var(--box-shadow-dark); //rgba(73, 39, 40, 0.58);
}

.modal-wide {
  width:79%;
  max-width:79%;
}

.ami-modal {

  &.ami-modal-type-1-border {
    box-shadow: var(--box-shadow-light);
    border: solid 1px var(--light-gray);
  }

  .ami-modal-type-1-body {
    background-color: var(--light-gray);
  }

  .ami-modal-type-2-body {
    background-color: var(--white);
  }

  .ami-modal-close-btn {
    position:absolute;
    top:10px;
    right: 10px;
    z-index: 1;
  }

  .ami-modal-title {
    padding-top:25px;
  }
}


/* =============================================================================
   Date Picker
   ========================================================================== */

.react-datepicker-popper {
  z-index: 10 !important;
}

.ami-date-picker .react-datepicker__input-container input {
  width: 100%;
  border:  1px solid black;
  border-radius: 4px;
  height: 50px;
}

/* =============================================================================
   Header
   ========================================================================== */



/* =============================================================================
   Main
   ========================================================================== */





/* =============================================================================
   Footer
   ========================================================================== */





/* =============================================================================
   Media Queries
   ========================================================================== */


//@media only screen and (max-width: 1199px) {
//}
//
//@media only screen and (max-width: 1024px) {
//}
//
//@media only screen and (max-width: 991px) {
//}
//
//@media only screen and (max-width: 767px) {
//  h1, .h1 {
//  }
//
//  h2, .h2 {
//  }
//
//  h3, .h3 {
//  }
//
//  h4, .h4 {
//  }
//
//  h5, .h5 {
//  }
//
//  h6, .h6 {
//  }
//
//
//  p {
//
//    &.lg {
//    }
//
//    &.md {
//    }
//  }
//}

//@media only screen and (max-width: 575px) {
//  h1, .h1 {
//    font-size: 35px;
//  }
//}


//@media only screen and (max-width: 360px) {
//}


//@media only screen and (max-width: 300px) {
//}

//@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
//}

// @media only screen and (max-width: 900px) {

//   .custom-carousel {
//     height: 1300px;
//   }
// }

// @media only screen and (max-width: 450px) {

//   .custom-carousel {
//     height: 1200px;
//   }
// }

/* =============================================================================
   Carousel style Overrides
   ========================================================================== */
// need to be set here because of encapsulation

.carousel-indicators {
  background-color: rgba(255, 255 ,255 ,.9); //todo
  border-radius: 20px;
  height: 30px;
  margin-right: auto;
  margin-left: auto;
  padding: 10px;
  width: fit-content;

  li {
    box-sizing: border-box;
    width: 10px;
    height: 10px;
    border: 1px solid var(--secondary);
    background: var(--white);

    &.active{
      background: var(--secondary);
    }
  }
}

.testimonial-carousel {
  .carousel-indicators {
    margin-left: 0px;
  }
}

.main-carousel {

  height: 275px;
  //background-color: red;

  width:100%;
  border-radius: 8px;
  overflow: hidden;

  .carousel-inner {

  }

  .carousel-indicators {
    position: absolute;
    top:85%;
    margin-right: 10px;
  }
}
